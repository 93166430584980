<template>
  <div class="upload-from-mobile__videos">
    <LibraryVideoCard
      v-for="video in videosFromMobile"
      :key="video.id"
      :video="video"
      component-id="veels-details"
      @set-remove-video="getRemoveVideo"
    />

    <div
      v-if="importedVideo.url"
      class="imported-video"
    >
      <div
        v-if="importedVideoLoading"
        class="imported-video-loading"
        :style="{ width: `calc(100% - ${importedVideoLoading}%)` }"
      />

      <div
        v-if="importedVideoLoading"
        class="imported-video-text"
      >
        Importando..
      </div>
      <LibraryVideoCard
        :video="importedVideo"
      />
    </div>

    <FormKit
      id="upload-video"
      type="file"
      accept="video/*"
      name="videos"
      @change="handleFile"
    />

    <label
      class="import"
      :class="{ 'is-importing': importedVideoLoading != 0 }"
      for="upload-video"
    >
      <div
        class="device-btn"
      >
        <AddLineIcon />
        <span class="text">Adicionar um vídeo</span>
      </div>
    </label>

    <!-- <div
      v-if="isImportFromLibrary"
      class="upload-from-library__background"
      @click="toggleUploadFromLibrary(false)"
    /> -->

    <PopupConfirmation
      v-if="confirmationPopupActive"
      :description="`Tem certeza que deseja remover o vídeo '${removeVideo?.name}' ? `"
      title-confirm="Remover"
      @confirm-action="confirmRemove"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Mqtt from '@/config/mixins/mqtt';

export default {
  name: "UploadFromMobileVideos",

  mixins: [Mqtt],

  data: () => ({
    importedVideo: {},
    importedVideoLoading: 0,
    // isImportFromLibrary: false,
  }),

  computed: {
    ...mapState(['videosFromMobile', 'confirmationPopupActive']),

    isComingFromData () {
      return {
        page: this.$route.query?.page,
        id: this.$route.query?.id,
      }
    }
  },

  methods: {
    ...mapActions([
      'setVideosFromMobile',
      'removeVideoFromLibrary',
      'setConfirmationPopupActive',
      'runUploadVideo',
      'removeVideosFromMobile'
    ]),

    async confirmRemove(status) {
      if (status) {
        await this.removeVideoFromLibrary({...this.removeVideo})
        this.removeVideosFromMobile({...this.removeVideo})
        this.sendMqttMessage(JSON.stringify({ isComingFromData: {...this.isComingFromData}, status: 'remove', video: { ...this.removeVideo } }))
      }

      this.setConfirmationPopupActive(false);
      this.removeVideo = null
    },

    getRemoveVideo (video) {
      this.removeVideo = { ...video };
      this.setConfirmationPopupActive(true);
    },
    
    async handleFile(event) {
      const file = event.target.files[0];

      if (file) {
        if (file.size <= 30 * 1024 * 1024) {
          const newUploadedVideo = {
            name: file.name,
            url: URL.createObjectURL(file),
          };

          this.importedVideo = {...newUploadedVideo};
        } else {
          alert(`O arquivo ${file.name} excede o limite de 30MB e não foi adicionado.`);
        }

        event.target.value = null;
      }

      let response = null;
      try {
        this.importedVideoLoading = 10;
        response = await this.runUploadVideo(file);
        this.importedVideoLoading = 50;
      } catch (e) {
        console.log(e);
      } finally {
        this.importedVideoLoading = 90;
        setTimeout(() => {
          this.setVideosFromMobile({...response?.value?.data?.video});
          this.sendMqttMessage(JSON.stringify({ isComingFromData: {...this.isComingFromData}, video: {...response?.value?.data?.video} }))
          this.importedVideo = {}

          this.importedVideoLoading = 0;
        }, 1500);
      }
    }
  }
};
</script>

<style lang="scss">
  .upload-from-mobile__videos {
    display: flex;
    flex-flow: wrap;
    overflow-x: auto;
    justify-content: center;
    gap: 20px;

    .formkit-outer {
      display: none;
    }

    .imported-video {
      position: relative;
      width: 150px;
      max-width: calc(50% - 10px);

      &-loading {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 200px;
        background: rgba(0, 0, 0, 0.7);
        z-index: 1;
        border-radius: 8px;
        backdrop-filter: blur(5px);
        transition: all .5s;
      }

      &-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $white;
        z-index: 2;
        font-size: 12px;
      }

      .library-video__card {
        max-width: unset;

        .preview {
          width: 150px;
        }
      }
    }

    .library-video__card {
      width: 150px !important;
      max-width: calc(50% - 10px) !important;

      .preview {
        height: 200px !important;
      }
    }

    .import {
      border: 1px dashed $grayscale-500;
      padding: 10px;
      border-radius: 20px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      width: 150px;
      max-width: calc(50% - 10px);
      height: 200px;
      gap: 10px;
      cursor: pointer;

      &.is-importing {
        opacity: 0.5;
        pointer-events: none;
      }

      .device-btn {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        border: 0;
        cursor: pointer;
        transition: all .4s;
        gap: 10px;

        &:hover {
          opacity: 0.5;
        }

        .text {
          color: $black-500;
          font-size: 14px;
          line-height: 17px;
        }

        svg {
          width: 20px;
          color: $black-500;
        }
      }
    }
  }
</style>
