<template>
  <div class="upload-from-library">
    <span
      class="close"
      @click="$emit('setCloseUploadFromLibrary', false)"
    >
      <CloseLineIcon />
    </span>

    <div class="upload-from-library-actions">
      <ButtonImport icon="GalleryView2Icon">
        <template #text>
          Importar da biblioteca
        </template>
      </ButtonImport>

      <ButtonDefault
        v-if="hasVideos"
        @click="setUseVideosFromLibrary"
      >
        Utilizar arquivos
        <ArrowRightSLine />
      </ButtonDefault>
    </div>

    <LibraryVideos
      :component-id="componentId"
      :has-select="true"
      :has-remove="false"
    />
  </div>
</template>

<script>
import LibraryVideos from "@/components/global/LibraryVideos.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    LibraryVideos
  },

  props: {
    componentId: {
      type: String,
      default: () => 'upload-from-library'
    }
  },

  emits: ['setFromLibrary', 'setCloseUploadFromLibrary'],

  computed: {
    ...mapState(['libraryVideosSelected', 'newVeelsForm', 'libraryVideos']),

    hasVideos () {
      return (this.libraryVideos?.videos?.data || []).length > 0
    }
  },

  methods: {
    ...mapActions(['setNewVeelsForm']),
    setUseVideosFromLibrary() {
      this.$emit('setFromLibrary', [...this.libraryVideosSelected]);
      this.$emit('setCloseUploadFromLibrary', false);
    }
  }
}
</script>

<style lang="scss">
.upload-from-library {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  z-index: 10;
  width: 90vw;
  height: 90vh;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  padding: 0;

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    display: flex;
    cursor: pointer;

    svg {
      width: 20px;
      color: $grayscale-400;
    }
  }

  &-actions {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    justify-content: space-between;
    margin: auto 85px;
    margin-top: 40px;

    .button-import {
      width: 340px;
      height: 78px;
      flex-flow: nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
    }

    .button-default {
      width: max-content;
      z-index: 1;
    }
  }

  .library-videos {
    padding: 0;
    max-height: calc(100% - 185px);
    overflow-y: auto;
    padding-bottom: 50px;
    padding-left: 85px;

    .pagination {
      margin: 0;
      margin-top: 50px;
    }
  }
}
</style>