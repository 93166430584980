<template>
  <div class="pagination">
    <ul
      data-grid
      class="pagination__wrapper"
    >
      <li
        v-if="hasPrevious"
        data-grid
        role="option"
        title="Página anterior"
        class="pagination__route prev"
        @click.stop="() => handlePageNavigate(currentPage - 1)"
      >
        <ArrowRightSLine />
      </li>


      <li
        v-for="page in pageList"
        :key="page"
        data-grid
        role="button"
        title="page"
        class="pagination__route"
        :class="{ 'pagination__route--selected': page === currentPage }"
        @click.stop="handlePageNavigate(page)"
      >
        {{ page }}
      </li>

      <li
        v-if="hasNext"
        data-grid
        role="button"
        title="Próxima página"
        class="pagination__route"
        @click.stop="() => handlePageNavigate(currentPage + 1)"
      >
        <ArrowRightSLine />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Pagination',

  props: {
    /**
     * @type {PropType<IPagination>}
     */
    data: {
      type: Object,
      required: true
    },

    /**
     * @type {PropType<number>}
     */
    displayingPages: {
      type: Number,
      default: () => 5
    }
  },

  emits: [
    'navigate'
  ],

  computed: {
    /**
     *
     * @returns {number}
     */
    lastPage () {
      const { data } = this

      return data?.last_page
    },

    /**
     *
     * @returns {number}
     */
    currentPage () {
      const { data } = this

      return data?.current_page
    },

    /**
     *
     * @returns {boolean}
     */
    hasPrevious () {
      const { currentPage } = this

      return currentPage > 1
    },

    /**
     *
     * @returns {boolean}
     */
    hasNext () {
      const { currentPage, lastPage } = this

      return currentPage < lastPage
    },

    /**
     *
     * @returns {number[]}
     */
    pageList () {
      const { displayingPages, lastPage, currentPage } = this

      const minPages = Math.min(displayingPages, lastPage)

      const offset = Math.max(0, Math.min(currentPage - Math.ceil(minPages / 2), lastPage - minPages))

      return Array.from({ length: minPages }, (_, index) => offset + index + 1)
    }
  },

  methods: {
    /**
     *
     * @param page {number}
     */
    handlePageNavigate (page) {
      if (page < 1 || page > this.lastPage) return

      this.$emit('navigate', page)
    }
  }
}
</script>

<style lang="scss">
.pagination {
  width: 100%;
  display: flex;
  margin: 20px;

  &__wrapper {
    width: 100%;
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding-block: 0;
  }

  &__route {
    width: 35px;
    height: 35px;
    border-radius: 4px;
    transition: .2s ease-in-out;
    border: solid 1px $black-500;
    background-color: transparent;
    list-style: none;
    font-size: 1.3rem;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;

    svg {
      width: 11px;
      height: 11px;

      path:last-child {
        stroke: $black-500;
      }
    }

    &.prev {
      transform: rotate(-180deg);
    }

    &--selected {
      color: $white;
      background-color: $black-500;
      border-color: $black-500;
    }
  }
}
</style>
