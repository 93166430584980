<template>
  <div class="videos">
    <div
      v-if="isUpdateBullet"
      class="bullets-form__close"
      @click="toggleBulletsForm(false)"
    />

    <BulletsForm
      v-if="isUpdateBullet"
      :bullet="bulletToEdit"
      :enabled-videos="enabledVideos"
      @close-bullets-form="toggleBulletsForm"
      @notify-updated-bullet="updatedBullet"
    />

    <LibraryVideoCard
      v-for="video in veelsDetails?.videos"
      :key="video.id"
      :video="video.video"
      component-id="veels-details"
      :has-bullet-edit="true"
      @set-remove-video="getRemoveVideo"
      @set-edit-bullet="setUpdateBullet"
    />

    <div
      v-if="importedVideo.url"
      class="imported-video"
    >
      <div
        v-if="importedVideoLoading"
        class="imported-video-loading"
        :style="{ width: `calc(100% - ${importedVideoLoading}%)` }"
      />

      <div
        v-if="importedVideoLoading"
        class="imported-video-text"
      >
        Importando..
      </div>
      <LibraryVideoCard
        :video="importedVideo"
      />
    </div>

    <FormKit
      id="upload-video"
      type="file"
      accept="video/*"
      name="videos"
      @change="handleFile"
    />

    <div
      class="import"
      :class="{ 'is-importing': importedVideoLoading != 0 }"
    >
      <label
        class="device-btn"
        for="upload-video"
      >
        <InboxUnarchiveLineIcon />
        <span class="text">Importar do Computador</span>
      </label>
      
      <button
        class="library-btn"
        @click="toggleUploadFromLibrary(true)"
      >
        <GalleryView2Icon />
        <span class="text">Importar da Biblioteca</span>
      </button>

      <button
        class="mobile-btn"
        @click="toggleUploadFromMobile(true)"
      >
        <SmartphoneLineIcon />
        <span class="text">Importar do celular</span>
      </button>
    </div>

    <div
      v-if="isImportFromLibrary"
      class="upload-background"
      @click="toggleUploadFromLibrary(false)"
    />

    <UploadFromLibrary 
      v-if="isImportFromLibrary"
      component-id="veels-details-upload"
      @set-from-library="getVideosFromLibrary"
      @set-close-upload-from-library="closeUploadFromLibrary"
    />

    <div
      v-if="isImportFromMobile"
      class="upload-background"
      @click="toggleUploadFromMobile(false)"
    />

    <UploadFromMobile
      v-if="isImportFromMobile"
      :is-coming-from-params="{ page: 'veels-details', id: veelsDetails?.id }"
      @set-close-upload-from-mobile="closeUploadFromMobile" 
    />

    <PopupConfirmation
      v-if="confirmationPopupActive"
      :description="`Tem certeza que deseja remover o vídeo '${removeVideo?.name}' ? `"
      title-confirm="Remover"
      @confirm-action="confirmRemove"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import BulletsForm from "../../../components/global/BulletsForm"

export default {
  name: "VeelsDetailsVideos",

  components: {
    BulletsForm
  },

  data: () => ({
    importedVideo: {},
    importedVideoLoading: 0,
    isImportFromLibrary: false,
    isImportFromMobile: false,
    isUpdateBullet: false,
    bulletToEdit: {}
  }),

  computed: {
    ...mapState(['veelsDetails', 'confirmationPopupActive']),

    enabledVideos () {
      const veelsEnabledVideos = this.veelsDetails.videos.filter(video => (video.video?.bullets || []).length < 1)

      return veelsEnabledVideos.map(item => item.video)
    }
  },

  mounted() {
    this.emitter.on('toggle-upload-from-mobile-veels-details', this.toggleUploadFromMobile);
  },

  unmounted() {
    this.emitter.off('toggle-upload-from-mobile-veels-details', this.toggleUploadFromMobile);
  },

  methods: {
    ...mapActions([
      'setVeelsDetails',
      'removeVideoFromVeels',
      'setConfirmationPopupActive',
      'runUploadVideo',
      'runLinkVideos',
      'setLibraryVideosSelected',
      'searchVeelsDetails'
    ]),

    toggleUploadFromLibrary(status) {
      this.isImportFromLibrary = status;

      if (!status) {
        this.setLibraryVideosSelected({ video: {}, status: 'clear' });
      }
    },

    closeUploadFromLibrary(status) {
      this.isImportFromLibrary = status;
      this.setLibraryVideosSelected({ video: {}, status: 'clear' });
    },

    async confirmRemove(status) {
      if (status) {
        const videoData = this.veelsDetails.videos.find(videoData => videoData.video.id === this.removeVideo.id);
        if (Object.keys(videoData || {}).length > 0) {
          await this.removeVideoFromVeels({
            veels_id: this.$route.params.veels_id,
            id: videoData.id
          })
        }
      }

      this.setConfirmationPopupActive(false);
      this.removeVideo = null
    },

    async getVideosFromLibrary (libraryVideosSelected) {
      let selecteds = libraryVideosSelected.map(obj => {
        return {
          video: {
            ...obj
          }
        }
      });

      let videos = [...this.veelsDetails.videos] || [];
      const concatedVideos = videos.concat([...selecteds]);
      this.setVeelsDetails({
        videos: concatedVideos
      });

      const { error } = await this.runLinkVideos({
        veels_id: this.veelsDetails.id,
        videos: [...libraryVideosSelected]
      });

      if (error) {
        this.setVeelsDetails({
          videos: [...videos]
        });

        return alert('Desculpa, detectamos uma falha ao importar os vídeos da biblioteca, tente mais tarde ou contate nossa equipe de suporte.');
      }
    },

    getRemoveVideo (video) {
      this.removeVideo = { ...video };
      this.setConfirmationPopupActive(true);
    },

    toggleUploadFromMobile(status) {
      this.isImportFromMobile = status;
    },

    closeUploadFromMobile(status) {
      this.isImportFromMobile = status;
    },
    
    async handleFile(event) {
      const file = event.target.files[0];

      if (file) {
        if (file.size <= 30 * 1024 * 1024) {
          const newUploadedVideo = {
            name: file.name,
            url: URL.createObjectURL(file),
          };

          this.importedVideo = {...newUploadedVideo};
        } else {
          alert(`O arquivo ${file.name} excede o limite de 30MB e não foi adicionado.`);
        }

        event.target.value = null;
      }

      let response = null;
      try {
        this.importedVideoLoading = 10;
        response = await this.runUploadVideo(file);
        this.importedVideoLoading = 50;

        await this.runLinkVideos({
          veels_id: this.veelsDetails.id,
          videos: [response?.value?.data?.video]
        })
      } catch (e) {
        console.log(e);
      } finally {
        this.importedVideoLoading = 90;
        setTimeout(() => {
          let videos = (this.veelsDetails?.videos || []).length > 0 ? [...this.veelsDetails.videos] : [];
          videos.push({ video: {...response?.value?.data?.video} });
          this.setVeelsDetails({
            videos
          });
          this.importedVideo = {}

          this.importedVideoLoading = 0;
        }, 1500);
      }
    },

    async toggleBulletsForm (status) {
      this.isUpdateBullet = !!status;

      if (!status) {
        await this.searchVeelsDetails(this.$route.params.veels_id);
      }
    },

    setUpdateBullet (video) {
      let _video = { ...video };
      this.bulletToEdit = {};

      if ((_video.bullets || []).length > 0) {
        delete _video.bullets;

        this.toggleBulletsForm(true);
        this.bulletToEdit = {
          bullet: video.bullets[0],
          video: {..._video}
        }
      }

      this.toggleBulletsForm(true);
    },

    updatedBullet (bullet) {
      const videos = [...this.veelsDetails.videos];

      videos.forEach(video => {
        if (video.video && video.video.bullets) {
          video.video.bullets = video.video.bullets.map(b => 
            b.id === bullet.id ? bullet : b
          );
        }
      });

      this.setVeelsDetails({
        videos
      });
    }
  }
};
</script>

<style lang="scss">
  .bullets-form__close {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 9;
  }

  .videos {
    display: flex;
    flex-flow: wrap;
    overflow-x: auto;
    gap: 20px;

    .formkit-outer {
      display: none;
    }

    .imported-video {
      position: relative;
      width: 280px;

      &-loading {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 360px;
        background: rgba(0, 0, 0, 0.7);
        z-index: 1;
        border-radius: 8px;
        backdrop-filter: blur(5px);
        transition: all .5s;
      }

      &-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $white;
        z-index: 2;
        font-size: 13px;
      }
    }

    .import {
      border: 1px dashed $grayscale-300;
      padding: 10px;
      border-radius: 20px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      width: 280px;
      height: 360px;
      gap: 10px;
      cursor: pointer;

      &.is-importing {
        opacity: 0.5;
        pointer-events: none;
      }

      .device-btn, .library-btn, .mobile-btn {
        background: $grayscale-200;
        padding: 10px 15px;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: flex-start;
        border-radius: 8px;
        border: 0;
        width: 200px;
        height: 70px;
        cursor: pointer;
        transition: all .4s;

        &:hover {
          opacity: 0.5;
        }

        .text {
          color: $black-500;
          font-size: 13px;
          line-height: 17px;
        }

        svg {
          width: 20px;
          color: $black-500;
        }
      }

      .library-btn {
        svg {
          path {
            fill: $purple-500;
          }
        }
      }

      .mobile-btn {
        svg {
          path {
            fill: $grayscale-600;
          }
        }
      }
    }

    .upload-background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.4);
      z-index: 9;
    }
  }
</style>
