// eslint-disable-next-line
import { createApp } from "vue";
import LogoSVG from "../components/global/LogoSVG.vue";
import ButtonDefault from "../components/global/ButtonDefault.vue";
import ButtonImport from "../components/global/ButtonImport.vue";
import GlobalHero from "../components/global/GlobalHero.vue";
import GlobalTabs from "../components/global/GlobalTabs.vue";
import PopupConfirmation from "../components/global/PopupConfirmation.vue"
import Pagination from "../components/global/Pagination.vue";
import LibraryVideoCard from "../components/global/LibraryVideoCard.vue";
import UploadFromLibrary from "../components/global/UploadFromLibrary.vue";
import UploadFromMobile from "../components/global/UploadFromMobile.vue";

import AddLineIcon from "../components/icons/AddLineIcon.vue";
import GalleryView2Icon from "../components/icons/GalleryView2Icon.vue";
import Home3LineIcon from "../components/icons/Home3LineIcon.vue";
import PlayLineIcon from "../components/icons/PlayLineIcon.vue";
import Settings3LineIcon from "../components/icons/Settings3LineIcon.vue";
import ArrowRightSLine from "../components/icons/ArrowRightSLine.vue";
import Notification2LineIcon from "../components/icons/Notification2LineIcon.vue";
import ArrowScaleUpIcon from "../components/icons/ArrowScaleUpIcon.vue";
import UploadCloud2LineIcon from "../components/icons/UploadCloud2LineIcon.vue";
import InboxUnarchiveLineIcon from "../components/icons/InboxUnarchiveLineIcon.vue";
import FocusLineIcon from "../components/icons/FocusLineIcon.vue";
import EditBoxLineIcon from "../components/icons/EditBoxLineIcon.vue";
import SearchLineIcon from "../components/icons/SearchLineIcon.vue";
import Filter3FillIcon from "../components/icons/Filter3FillIcon.vue";
import EyeLineIcon from "../components/icons/EyeLineIcon.vue";
import ChartLineIcon from "../components/icons/ChartLineIcon.vue";
import Heart3LineIcon from "../components/icons/Heart3LineIcon.vue";
import MoneyDollarBoxLineIcon from "../components/icons/MoneyDollarBoxLineIcon.vue";
import CloseLineIcon from "../components/icons/CloseLineIcon.vue";
import CheckBoxCircleLineIcon from "../components/icons/CheckBoxCircleLineIcon.vue";
import DeleteBinLineIcon from "../components/icons/DeleteBinLineIcon.vue";
import Loader4LineIcon from "../components/icons/Loader4LineIcon.vue";
import User3LineIcon from "../components/icons/User3LineIcon.vue";
import FileCopyLineIcon from "../components/icons/FileCopyLineIcon.vue";
import SmartphoneLineIcon from "../components/icons/SmartphoneLineIcon.vue";
import WhatsappLineIcon from "../components/icons/WhatsappLineIcon.vue";
import Chat4LineIcon from "../components/icons/Chat4LineIcon.vue";
import StarLineIcon from "../components/icons/StarLineIcon.vue";
import TableViewIcon from "../components/icons/TableViewIcon.vue";
import RulerLineIcon from "../components/icons/RulerLineIcon.vue";
import FileZipLineIcon from "../components/icons/FileZipLineIcon.vue";
import LoopRightLineIcon from "../components/icons/LoopRightLineIcon.vue";
import CheckDoubleLineIcon from "../components/icons/CheckDoubleLineIcon.vue";

import GridWithAuth from "../components/grid/GridWithAuth.vue";
import GridWithoutAuth from "../components/grid/GridWithoutAuth.vue";

export default {
  install(app) {
    /**
     * General
     */
    app.component("LogoSVG", LogoSVG);
    app.component("ButtonDefault", ButtonDefault);
    app.component("ButtonImport", ButtonImport);
    app.component("GlobalHero", GlobalHero);
    app.component("GlobalTabs", GlobalTabs);
    app.component("PopupConfirmation", PopupConfirmation);
    app.component("Pagination", Pagination);
    app.component("LibraryVideoCard", LibraryVideoCard);
    app.component("UploadFromLibrary", UploadFromLibrary);
    app.component("UploadFromMobile", UploadFromMobile);

    /**
     * Grid
     */
    app.component("GridWithAuth", GridWithAuth);
    app.component("GridWithoutAuth", GridWithoutAuth);

    /**
     * Icons
     */
    app.component("AddLineIcon", AddLineIcon);
    app.component("GalleryView2Icon", GalleryView2Icon);
    app.component("Home3LineIcon", Home3LineIcon);
    app.component("Settings3LineIcon", Settings3LineIcon);
    app.component("PlayLineIcon", PlayLineIcon);
    app.component("ArrowRightSLine", ArrowRightSLine);
    app.component("Notification2LineIcon", Notification2LineIcon);
    app.component("ArrowScaleUpIcon", ArrowScaleUpIcon);
    app.component("UploadCloud2LineIcon", UploadCloud2LineIcon);
    app.component("InboxUnarchiveLineIcon", InboxUnarchiveLineIcon);
    app.component("FocusLineIcon", FocusLineIcon);
    app.component("EditBoxLineIcon", EditBoxLineIcon);
    app.component("SearchLineIcon", SearchLineIcon);
    app.component("Filter3FillIcon", Filter3FillIcon);
    app.component("EyeLineIcon", EyeLineIcon);
    app.component("ChartLineIcon", ChartLineIcon);
    app.component("Heart3LineIcon", Heart3LineIcon);
    app.component("MoneyDollarBoxLineIcon", MoneyDollarBoxLineIcon);
    app.component("CloseLineIcon", CloseLineIcon);
    app.component("CheckBoxCircleLineIcon", CheckBoxCircleLineIcon);
    app.component("DeleteBinLineIcon", DeleteBinLineIcon);
    app.component("Loader4LineIcon", Loader4LineIcon);
    app.component("User3LineIcon", User3LineIcon);
    app.component("FileCopyLineIcon", FileCopyLineIcon);
    app.component("SmartphoneLineIcon", SmartphoneLineIcon);
    app.component("WhatsappLineIcon", WhatsappLineIcon);
    app.component("Chat4LineIcon", Chat4LineIcon);
    app.component("StarLineIcon", StarLineIcon);
    app.component("TableViewIcon", TableViewIcon);
    app.component("RulerLineIcon", RulerLineIcon);
    app.component("FileZipLineIcon", FileZipLineIcon);
    app.component("LoopRightLineIcon", LoopRightLineIcon);
    app.component("CheckDoubleLineIcon", CheckDoubleLineIcon);
  },
};
