<template>
  <GridWithAuth class="page-library">
    <GlobalHero>
      <h2 class="title">
        Biblioteca
      </h2>
    </GlobalHero>

    <GlobalTabs
      :tabs="tabs"
      @set-searched-term="getSearchedTerm"
    />

    <LibraryActions v-if="false" />

    <div class="page-library__content">
      <span class="page-library__content-count">{{ totalVideos > 1 ? `${totalVideos} videos encontrados` : `${totalVideos} video encontrado` }} </span>

      <LibraryVideos
        :component-id="'library-page'"
        @remove-video="getRemoveVideo"
      />
    </div>

    <PopupConfirmation
      v-if="confirmationPopupActive"
      :description="`Tem certeza que deseja remover o vídeo '${removeVideo?.name}' ? `"
      title-confirm="Remover"
      @confirm-action="confirmRemove"
    />
  </GridWithAuth>
</template>

<script>
import LibraryVideos from "@/components/global/LibraryVideos.vue"
import LibraryActions from "./components/LibraryActions.vue"
import { mapActions, mapState } from "vuex";

export default {
  name: "Library",
  components: {
    LibraryVideos,
    LibraryActions
  },
  data: () => ({
    removeVideo: null
  }),
  computed: {
    ...mapState(['confirmationPopupActive', 'libraryVideos']),
    tabs () {
      return [
        { label: 'Todos', value: 'all' },
        // { label: 'Usados', value: 'used' },
        // { label: 'Não usados', value: 'not-used' }
      ]
    },
    totalVideos () {
      return this.libraryVideos?.videos?.total
    }
  },

  watch: {
    libraryVideosFilters: {
      deep: true,
      immediate: true,
      async handler () {
        this.searchLibraryVideos()
      }
    }
  },

  methods: {
    ...mapActions([
      'setConfirmationPopupActive', 
      'removeVideoFromLibrary', 
      'searchLibraryVideos',
      'setLibraryVideosFilters'
    ]),

    async confirmRemove(status) {
      if (status) {
        await this.removeVideoFromLibrary(this.removeVideo)
      }

      this.setConfirmationPopupActive(false);
      this.removeVideo = null
    },

    getRemoveVideo (video) {
      this.removeVideo = { ...video };
      this.setConfirmationPopupActive(true);
    },

    getSearchedTerm (term) {
      this.setLibraryVideosFilters({
        name: term,
        page: 1,
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.page-library {
  // background-color: $grayscale-100;
  // padding-bottom: 50px;

  .title {
    font-size: 20px;
    font-weight: 600;
    color: $black-500;
  }

  &__content {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-flow: column;

    &-count {
      width: 100%;
      text-align: left;
      padding: 0 48px;
      padding-top: 30px;
      font-size: 15px;
      color: $grayscale-500;
    }
  }
}
</style>
