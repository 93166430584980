<template>
  <div class="home-metrics">
    <div class="home-metrics__header">
      <h2 class="title">
        Visão geral
      </h2>

      <div class="home-metrics__dropdown">
        <!-- <div class="selected">
          <span class="value">Esta semana</span>
          <ArrowRightSLine />
          
        </div> -->
        <select
          v-model="dateType"
          class="select"
        >
          <option value="semanal">
            Ultimos 7 dias
          </option>
          <option value="mensal">
            Ultimos 30 dias
          </option>
          <option value="beginning">
            Desde o início
          </option>
        </select>
      </div>
    </div>

    <div
      v-if="!hasGA4"
      class="gid-not-found"
    >
      <span class="message">
        Seu Google Analytics 4 ainda não foi configurado. Configure agora mesmo!
      </span>

      <ButtonDefault @click="$router.push({ name: 'settings-ga4' })">
        Configurar agora
        <ArrowRightSLine />
      </ButtonDefault>
    </div>

    <div
      class="home-metrics__boxes"
      :class="{ loading }"
    >
      <div class="container">
        <HomeMetricBox :data="revenue" />
        <HomeMetricBox :data="conversion" />
        <HomeMetricBox :data="averageTicket" />
        <HomeMetricBox :data="pageViews" />
        <HomeMetricBox :data="sessionDuration" />
        <HomeMetricBox :data="returningUsers" />
        <!-- <HomeGrowthChart
          :chart-data="chartData"
          :chart-options="chartOptions"
        /> -->
        <!-- <HomeMetricBox :data="conversion" /> -->
        <!-- <HomeMetricBox :data="revenue" /> -->
        <!-- <HomeMetricBox :data="conversion" /> -->
      </div>

      <div class="container column">
        <HomeMetricBox :data="videosAnalytics" />
        <HomeMetricBox :data="views" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, useStore } from "vuex";
import HomeMetricBox from "./HomeMetricBox.vue";
// import HomeGrowthChart from "./HomeGrowthChart.vue";

export default {
  components: {
    HomeMetricBox,
    // HomeGrowthChart
  },

  setup () {
    const store = useStore();
    store.dispatch('searchHomeHeroMetrics');
  },

  data: () => ({
    dateType: 'semanal',
    loading: false,
    // chartData: [
    //   ["Year", "Faturamento", "Expenses"],
    //   ["2013", 1000, 400],
    //   ["2014", 1170, 460],
    //   ["2015", 660, 1120],
    //   ["2016", 1030, 540],
    // ],
    // chartOptions: {
    //   title: "Crescimento e Resultados",
    //   hAxis: { title: "Período", titleTextStyle: { color: "#333" } },
    //   vAxis: { minValue: 0 },
    // },
  }),

  computed: {
    ...mapGetters(['getHomeAnalytics', 'getHomeHeroMetrics', 'getUser']),

    hasGA4 () {
      return this.getUser?.google_id && Object.keys(this.getHomeAnalytics || {}).length > 0
    },

    revenue () {
      //let revenueWithoutVeels = this.getHomeAnalytics?.before_avg?.totalRevenue || 0;
      let revenueWithVeels = this.getHomeAnalytics?.analytics_avg?.totalRevenue || 0;
     //let percent = this.calculatePercentageBeforeAndAfter(revenueWithoutVeels, revenueWithVeels)

      return {
        value_1: this.$formatCurrency(revenueWithVeels),
        id: "revenue",
        title: "Receita",
        description_1: "Receita após o <b>veels</b>",
        //percent
      }
    },

    conversion () {
      let transactionsWithoutVeels = this.getHomeAnalytics?.before_avg?.transactions || 0;
      let sessionsWithoutVeels = this.getHomeAnalytics?.before_avg?.sessions || 0;
      let beforeConversion = (transactionsWithoutVeels / sessionsWithoutVeels) * 100;
      
      let transactionsWithVeels = this.getHomeAnalytics?.analytics_avg?.transactions || 0;
      let sessionsWithVeels = this.getHomeAnalytics?.analytics_avg?.sessions || 0;
      let afterConversion = (transactionsWithVeels / sessionsWithVeels) * 100;

      let percent = this.calculatePercentageBeforeAndAfter(beforeConversion, afterConversion)

      return {
        value_1: (beforeConversion || 0).toFixed(2) + '%',
        value_2: (afterConversion || 0).toFixed(2) + '%',
        id: "conversion",
        title: "Taxa de conversão",
        description_1: "Conversão geral",
        description_2: "Conversão <b>veels</b>",
        percent,
      }
    },

    views () {
      let screenPageViewsWithoutVeels = this.getHomeAnalytics?.before_avg?.screenPageViews || 0;
      let totalUsersWithoutVeels = this.getHomeAnalytics?.before_avg?.totalUsers || 0;
      let sessionsWithoutVeels = this.getHomeAnalytics?.before_avg?.sessions || 0;
      const sumBefore = screenPageViewsWithoutVeels + totalUsersWithoutVeels + sessionsWithoutVeels;

      let screenPageViewsWithVeels = this.getHomeAnalytics?.analytics_avg?.screenPageViews || 0;
      let totalUsersWithVeels = this.getHomeAnalytics?.analytics_avg?.totalUsers || 0;
      let sessionsWithVeels = this.getHomeAnalytics?.analytics_avg?.sessions || 0
      const sumAfter = screenPageViewsWithVeels + totalUsersWithVeels + sessionsWithVeels;

      console.log('sumAfter', sumAfter, sumBefore)

      let percent = this.calculatePercentageBeforeAndAfter(sumBefore, sumAfter)

      return  {
        value_1: (screenPageViewsWithVeels || 0).toFixed(0),
        value_2: (totalUsersWithVeels || 0).toFixed(0),
        value_3: (sessionsWithVeels || 0).toFixed(0),
        id: "views",
        title: "Visualizações no site",
        description_1: "Total de páginas visitadas",
        description_2: "Total de usuários",
        description_3: "Total de sessões",
        percent,
      }
    },

    averageTicket () {
      let totalRevenueWithoutVeels = this.getHomeAnalytics?.before_avg?.totalRevenue || 0;
      let transactionsWithoutVeels = this.getHomeAnalytics?.before_avg?.transactions || 0;
      const averageBefore = totalRevenueWithoutVeels / transactionsWithoutVeels;

      let totalRevenueWithVeels = this.getHomeAnalytics?.analytics_avg?.totalRevenue || 0;
      let transactionsWithVeels = this.getHomeAnalytics?.analytics_avg?.transactions || 0;
      const averageAfter = totalRevenueWithVeels / transactionsWithVeels;

      let percent = this.calculatePercentageBeforeAndAfter(averageBefore, averageAfter)

      return {
        value_1: this.$formatCurrency(averageBefore),
        value_2: this.$formatCurrency(averageAfter),
        id: "average-ticket",
        title: "Ticket médio",
        description_1: "Ticket médio geral",
        description_2: "Ticket médio com <b>veels</b>",
        percent,
      }
    },

    pageViews () {
      let totalUsersWithoutVeels = this.getHomeAnalytics?.before_avg?.totalUsers || 0;
      let screenPageViewsWithoutVeels = this.getHomeAnalytics?.before_avg?.screenPageViews || 0;
      let pageViewsPerUserWithoutVeels = screenPageViewsWithoutVeels / totalUsersWithoutVeels;
      
      let totalUsersWithVeels = this.getHomeAnalytics?.analytics_avg?.totalUsers || 0;
      let screenPageViewsWithVeels = this.getHomeAnalytics?.analytics_avg?.screenPageViews || 0;
      let pageViewsPerUserWithVeels = screenPageViewsWithVeels / totalUsersWithVeels;

      let percent = this.calculatePercentageBeforeAndAfter(pageViewsPerUserWithoutVeels, pageViewsPerUserWithVeels)

      return  {
        value_1: (pageViewsPerUserWithoutVeels || 0).toFixed(2),
        value_2: (pageViewsPerUserWithVeels || 0).toFixed(2),
        id: "pageviews",
        title: "Páginas visitadas",
        description_1: "Páginas sem veels",
        description_2: "Páginas com <b>veels</b>",
        percent,
      }
    },

    sessionDuration () {
      let averageSessionDurationWithoutVeels = this.getHomeAnalytics?.before_avg?.averageSessionDuration || 0;
      let averageSessionDurationWithVeels = this.getHomeAnalytics?.analytics_avg?.averageSessionDuration || 0;

      let percent = this.calculatePercentageBeforeAndAfter(averageSessionDurationWithoutVeels, averageSessionDurationWithVeels);

      return  {
        value_1: this.formatDuration(averageSessionDurationWithoutVeels.toFixed(0)),
        value_2: this.formatDuration(averageSessionDurationWithVeels.toFixed(0)),
        id: "sessions",
        title: "Duração da sessão",
        description_1: "Duração sem veels",
        description_2: "Duração com <b>veels</b>",
        percent,
      }
    },

    returningUsers () {
      let returningUsersWithoutVeels = this.getHomeAnalytics?.before_avg?.totalUsers - this.getHomeAnalytics?.before_avg?.newUsers;
      let returningUsersWithVeels = this.getHomeAnalytics?.analytics_avg?.totalUsers - this.getHomeAnalytics?.analytics_avg?.newUsers;

      let percent = this.calculatePercentageBeforeAndAfter(returningUsersWithoutVeels, returningUsersWithVeels);

      return  {
        value_1: (returningUsersWithoutVeels || 0).toFixed(0),
        value_2: (returningUsersWithVeels || 0).toFixed(0),
        id: "returning-users",
        title: "Usuários retornantes",
        description_1: "Retorno sem veels",
        description_2: "Retorno com <b>veels</b>",
        percent,
      }
    },

    videosAnalytics () {
      return  {
        value_1: this.getHomeHeroMetrics?.analytics?.clicks || 0,
        value_2: this.getHomeHeroMetrics?.analytics?.likes || 0,
        value_3: this.getHomeHeroMetrics?.analytics?.shares || 0,
        id: "returning-users",
        title: "Videos no veels",
        description_1: "Visualizações",
        description_2: "Curtidas",
        description_3: "Compartilhamentos",
        percent: null,
      }
    },
  },

  watch: {
    dateType: {
      immediate: true,
      async handler (v) {
        const { start, end } = this.getDateRange(v);
        this.loading = true;
        await this.searchHomeAnalytics({ start, end });
        this.loading = false;
      }
    }
  },

  mounted() {
    //eslint-disable-next-line
    let _google = google;
    _google.charts.load('current', {'packages':['corechart']});
    _google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      var data = _google.visualization.arrayToDataTable([
        ['Year', 'Sales', 'Expenses'],
        ['2013',  1000,      400],
        ['2014',  1170,      460],
        ['2015',  660,       1120],
        ['2016',  1030,      540]
      ]);

      var options = {
        title: 'Company Performance',
        hAxis: {title: 'Year',  titleTextStyle: {color: '#333'}},
        vAxis: {minValue: 0}
      };

      var chart = new _google.visualization.AreaChart(document.getElementById('chart_div'));
      chart.draw(data, options);
    }
  },

  methods: {
    ...mapActions(['searchHomeAnalytics']),

    calculatePercentageBeforeAndAfter (before, after) {
      const increase = after - before;

      if (!after && !before) {
        return 0;
      }

      if (before <= 0) {
        return 100;
      }

      const increasePercentage = (increase / before) * 100;
      return parseFloat((increasePercentage || 0).toFixed(2));
    },

    formatDuration (seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}min ${remainingSeconds}seg`;
    },

    getDateRange(type) {
      const today = new Date();

      // Função auxiliar para formatar datas como 'YYYY-MM-DD'
      function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }

      let startDate;

      switch (type) {
        case 'semanal':
          // Range Semanal: Últimos 7 dias
          startDate = new Date(today); // Cria uma nova instância de Date para o start
          startDate.setDate(today.getDate() - 6); // Subtrai 7 dias
          break;

        case 'mensal':
          // Range Mensal: Últimos 30 dias
          startDate = new Date(today); // Cria uma nova instância de Date para o start
          startDate.setDate(today.getDate() - 29); // Subtrai 30 dias
          break;

        case 'beginning':
          // // Range Anual: Do primeiro dia do ano até o dia atual
          // console.log(this.getUser?.created_at);
          // startDate = new Date(today.getFullYear(), 0, 1); // Primeiro dia do ano
          startDate = new Date(this.getUser?.created_at);
          break;

        default:
          throw new Error('Tipo inválido. Use "semanal", "mensal" ou "anual".');
      }

      return {
        start: formatDate(startDate),
        end: formatDate(today)
      };
    }

  }
};
</script>

<style lang="scss">
.home-metrics {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 0 48px;
  gap: 54px;
  max-width: 1172px;
  margin-top: 48px;

  .gid-not-found {
    display: flex;
    flex-flow: nowrap;
    align-items: center;
    justify-content: space-between;
    background: lighten($yellow-500, 50%);
    padding: 10px 20px;
    border-radius: 8px;

    .message {
      color: darken($yellow-500, 30%);
      font-size: 15px;
    }

    .button-default {
      max-width: 200px;
      height: 30px;
      background: transparent;
      color: darken($yellow-500, 30%);
      text-decoration: underline;
      align-items: center;

      svg {
        width: 10px;
        height: 10px;
        transform: translateY(2px);
        path:last-child {
          stroke: darken($yellow-500, 30%);
        }
      }
    }
  }

  &__header {
    display: flex;
    flex-flow: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 1px solid $grayscale-300;

    .title {
      font-size: 20px;
      font-weight: 700;
      color: $black-500;
    }
  }

  &__dropdown {
    display: flex;
    flex-flow: column;

    .select {
      display: flex;
      flex-flow: nowrap;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      background: transparent;
      border: 0;
      width: max-content;
      color: $grayscale-600;
      font-weight: 600;
      outline: none;

      // .value {
      //   color: $grayscale-500;
      //   font-size: 15px;
      //   font-weight: 600;
      // }

      // svg {
      //   width: 6px;
      //   transform: rotate(90deg);
      //   margin-top: 2px;

      //   path:last-child {
      //     stroke: $grayscale-500;
      //   }
      // }
    }
  }

  &__boxes {
    display: flex;
    flex-flow: nowrap;
    align-items: flex-start;
    gap: 28px;
    transition: all .2s;

    &.loading {
      opacity: 0.2;
    }

    .container {
      display: flex;
      flex-flow: wrap;
      align-items: center;
      gap: 28px;
      width: 66%;

      &.column {
        flex-flow: column;
        width: 33%;

        .home-metric__box {
          width: 100%;
        }
      }
    }

    .home-metric__box {
      // background-color: transparent;
      // box-shadow: unset;
      // border: 1px solid $grayscale-500;
      width: calc(50% - 14px);
    }
  }
}
</style>
