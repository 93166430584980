<template>
  <div
    class="library-video__card"
  >
    <video
      class="preview"
      controls
    >
      <source
        :src="videoData?.url"
        type="video/mp4"
      >
      Seu navegador não suporta a tag de vídeo.
    </video>

    <input
      :id="`${componentId}-${videoData?.id}`"
      v-model="selected"
      type="checkbox"
      name="video"
      @change="setVideoSelected(videoData, selected)"
    >
    <label
      v-if="hasSelect"
      class="check"
      :for="`${componentId}-${videoData?.id}`"
    />

    <div class="library-video__card-actions">
      <span
        class="bullet optimization"
        :class="videoData?.compress_status"
        :title="compressTitle"
        @click="compressVideo()"
      >
        <component :is="compressStatus" />
      </span>

      <span
        v-if="(videoData?.bullets || []).length > 0 && hasBulletEdit"
        class="bullet"
        @click="$emit('setEditBullet', videoData)"
      >
        <component :is="`${videoData?.bullets[0].icon}-icon`" />
      </span>

      <span
        v-else-if="(videoData?.bullets || []).length < 1 && hasBulletEdit"
        title="Adicionar tabela de informações"
        class="bullet add"
        @click="$emit('setEditBullet', videoData)"
      >
        <AddLineIcon />
      </span>

      <span
        v-if="hasRemove"
        class="remove"
        @click="$emit('setRemoveVideo', videoData)"
      >
        <DeleteBinLineIcon />
      </span>
    </div>

    <span class="title">
      {{ videoData.name }}
    </span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    hasSelect: {
      type: Boolean,
      default: () => false
    },

    hasBulletEdit: {
      type: Boolean,
      default: () => false
    },

    video: {
      type: Object,
      default: () => {}
    },

    componentId: {
      type: String,
      default: () => 'library'
    },

    hasRemove: {
      type: Boolean,
      default: () => true
    }
  },

  emits: [
    'setRemoveVideo',
    'setEditBullet'
  ],

  data: () => ({
    selected: false,
    videoData: {}
  }),

  computed: {
    compressStatus () {
      return {
        done: 'check-double-line-icon',
        processing: 'loop-right-line-icon',
        in_queue: 'loop-right-line-icon',
        pending: 'file-zip-line-icon'
      }?.[this.videoData?.compress_status] || ''
    },

    compressTitle () {
      return {
        done: 'Video otimizado',
        processing: 'Processando',
        in_queue: 'Na fila para processar..',
        pending: 'Clique para otimizar o video'
      }?.[this.videoData?.compress_status] || ''
    }
  },

  created () {
    this.videoData = { ...this.video }
  },

  methods: {
    ...mapActions(['setLibraryVideosSelected', 'compressVideoFromLibrary']),

    setVideoSelected(video, status) {
      this.setLibraryVideosSelected({ video: {...video}, status });
    },

    async compressVideo () {
      if (this.videoData.compress_status !== 'pending') {
        const message =  {
          done: 'Seu vídeo já está otimizado!',
          processing: 'Aguarde alguns minutos, estamos otimizando seu vídeo.',
          in_queue: 'O vídeo está na fila para ser otimizado, por favor aguarde mais alguns minutinhos.',
        }?.[this.videoData?.compress_status] || ''

        return alert(message);
      }

      const { value, error } = await this.compressVideoFromLibrary(this.video.id);

      if (error) {
        return alert('Falha ao otimizar o vídeo, por favor, tente mais tarde ou entre em contato com nossa equipe.');
      }

      this.videoData = { ...value.video }
    }
  }
}
</script>

<style lang="scss" scoped>
.library-video__card {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 280px;
    position: relative;

   .preview {
    width: 100%;
    height: 360px;
    border-radius: 8px;
    object-fit: cover;
   }

   input {
    display: none;

    &:checked {
      & + .check:before {
        background: $blue-500;
      }

      & + .check:after {
        display: flex;
      }
    }
  }

  .check {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;

    &:before {
      content: '';
      display: flex;
      width: 19px;
      height: 19px;
      background: $white;
      border-radius: 4px;
    }

    &:after { 
      content: '';
      display: none;
      width: 8px;
      height: 4px;
      border-left: 2px solid #ffffff;
      border-bottom: 2px solid #ffffff;
      position: absolute;
      top: 5px;
      left: 5px;
      transform: rotate(-45deg);
    }
  }

  &-actions {
    right: 10px;
    top: 10px;
    position: absolute;
    width: max-content;
    height: max-content;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    gap: 10px;

    .remove, .bullet {
      width: 32px;
      height: 32px;
      background: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: $red-500;
      cursor: pointer;

      svg {
        color: $red-500;
        width: 17px;
      }
    }

    .bullet {
      &.optimization {
        background: $blue-500;
        top: 50px;

        &.done {
          background: $green-500 !important;
        }

        &.in_queue, &.processing {
          background: darken($yellow-500, 5%) !important;
        }

        svg {
          color: $white;
        }
      }

      &.add {
        gap: 10px;
        background: $white;
        color: $white;
        font-size: 13px;
        
        svg {
          color: $black-500;
        }
      }

      svg {
        color: $black-500;
      }
    }

  }

  .title {
    font-size: 15px !important;
    font-weight: normal !important;
    color: $grayscale-400 !important;
    text-align: left;
    margin-top: 13px;
    line-height: 20px;
    white-space: nowrap;        
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
  }
}
</style>