export default {
  namespaced: true,

  state: {
    libraryVideos: false,
    libraryVideosSelected: [],
    libraryVideosFilters: {
      page: 1,
      per_page: 10,
      name: ''
    }
  },

  getters: {
    getLibraryVideos: state => state.libraryVideos,
  },

  mutations: {
    SET_LIBRARY_VIDEOS: (state, value) => (state.libraryVideos = value),
    SET_LIBRARY_VIDEOS_SELECTED: (state, value) => {
      if (value.status === 'clear') {
        state.libraryVideosSelected = [];
        return;
      }

      if (value.status) {
        state.libraryVideosSelected.push({...value.video});
      } else {
        const index = state.libraryVideosSelected.findIndex(v => v.id === value.video.id);
        state.libraryVideosSelected.splice(index, 1);
      }
    },
  },

  actions: {
    setLibraryVideosFilters ({ state }, data) {
      Object.assign(state.libraryVideosFilters, {
        ...data
      })
    },

    setLibraryVideosSelected ({ commit }, { video, status }) {
      commit('SET_LIBRARY_VIDEOS_SELECTED', { video, status })
    },

    async searchLibraryVideos({ commit, state }) {
      let value = null;
      let error = null;

      const {page, per_page, name } = state.libraryVideosFilters
      const queryParams = new URLSearchParams({
        page,
        per_page,
        name
      }).toString()

      try {
        const response = await window
          .$request()
          .get(`/gallery/search?${queryParams}`)

        commit('SET_LIBRARY_VIDEOS', response.data);

        value = response.data;
      } catch (e) {
        console.error('[searchLibraryVideos]', error)
        error = e;
      }

      return {
        value,
        error
      };
    },

    async removeVideoFromLibrary({ commit, state }, data) {
      let value = null;
      let error = null;
      const { page } = state.libraryVideosFilters

      try {
        const response = await window
          .$request()
          .delete(`/gallery/${data.id}?page=${page}`)

        commit('SET_LIBRARY_VIDEOS', response.data);

        value = response.data;
      } catch (e) {
        console.error('[removeVideoFromLibrary]', error)
        error = e;
      }

      return {
        value,
        error
      };
    },

    async compressVideoFromLibrary({ commit }, id) {
      let value = null;
      let error = null;

      try {
        const response = await window
          .$request()
          .post(`/gallery/${id}/compress`)

        console.log(commit);

        value = response.data;
      } catch (e) {
        console.error('[compressVideoFromLibrary]', error)
        error = e;
      }

      return {
        value,
        error
      };
    },
  }
}
